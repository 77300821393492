import { useState, useEffect, useRef, useContext } from 'react'
import { supabase } from 'supabaseClient'
import { useParams, useNavigate, useOutletContext } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { Context } from 'Store'
import moment from 'moment'
import slugify from 'slugify'
import { hash } from 'helpers/helpers'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import AddIcon from '@mui/icons-material/Add'
import SaveIcon from '@mui/icons-material/Save'

import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import InputAdornment from '@mui/material/InputAdornment'

function EditTitle ({ item, close, closeReload }) {
  const { dbAlert } = useOutletContext()
  const [title, setTitle] = useState('')
  const [loading, setLoading] = useState(false)

  const titleChange = (ev, v) => setTitle(ev.target.value)

  const saveTitle = async () => {
    try {
      setLoading(true)

      const udata = {}
      if (title != item.title) udata.title = title

      let { data, error } = await supabase.from('repairs')
      .update(udata).match({ id: item.id })
      if (error) throw error

      dbAlert.current.showAlert('success')
      closeReload()
    } catch (error) {
      dbAlert.current.showAlert('error')
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setTitle(item.title)
  }, [])

  return (<>
    <DialogTitle>
      Edytuj tytuł
    </DialogTitle>
    <DialogContent>
      <Box sx={{ display: 'flex', pt: 1 }}>
        <TextField sx={{ width: 400 }} label="Czego dotyczy?" variant="outlined" size="small" value={title} onChange={titleChange} />
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => close()}>anuluj</Button>
      <LoadingButton
        variant="contained"
        disableElevation
        endIcon={<SaveIcon />}
        loading={loading}
        loadingPosition="end"
        onClick={saveTitle}
        disabled={title === ''}
      >zapisz</LoadingButton>
    </DialogActions>
  </>)
}

export default EditTitle
