import { useState, forwardRef, useImperativeHandle } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'

const ConfirmDelete = forwardRef(({ onConfirm }, ref) => {
  const [open, setOpen] = useState(false)
  const [payload, setPayload] = useState(null)

  const handleClose = () => {
    setPayload(payload)
    setOpen(false)
  }

  useImperativeHandle(ref, () => ({
    close() { handleClose() },
    confirm (payload) {
      setPayload(payload)
      setOpen(true)
    }
  }))

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-delete-title"
      aria-describedby="confirm-delete-description"
    >
      <DialogTitle id="confirm-delete-title">
        Czy na pewno chcesz usunąć ten element?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-delete-description">
          Wpis zostanie nieodwracalnie usunięty!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>anuluj</Button>
        <Button autoFocus color="error" onClick={() => onConfirm(payload)}>usuń</Button>
      </DialogActions>
    </Dialog>
  )
})

export default ConfirmDelete
