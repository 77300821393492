import { useState, forwardRef, useImperativeHandle } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'

const ConfirmEdit = forwardRef(({ onConfirm }, ref) => {
  const [open, setOpen] = useState(false)
  const [payload, setPayload] = useState(null)

  const handleClose = () => {
    setPayload(payload)
    setOpen(false)
  }

  useImperativeHandle(ref, () => ({
    close() { handleClose() },
    confirm (payload) {
      setPayload(payload)
      setOpen(true)
    }
  }))

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        Czy na pewno chcesz zmienić ten element?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Wpis zostanie zmieniony!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>anuluj</Button>
        <Button autoFocus color="error" onClick={() => onConfirm(payload)}>zmień</Button>
      </DialogActions>
    </Dialog>
  )
})

export default ConfirmEdit
