import { useState, forwardRef, useImperativeHandle } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

const DbAlert = forwardRef((props, ref) => {
  const [alertStatus, setAlertStatus] = useState('error')
  const [alertOpen, setAlertOpen] = useState(false)

  const messages = {
    success: 'Zmiany zostały zapisane!',
    error: 'Wystąpił błąd, spróbuj ponownie!'
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlertOpen(false)
  }

  useImperativeHandle(ref, () => ({
    showAlert(status) {
      setAlertStatus(status in messages ? status : 'error')
      setAlertOpen(true)
    }
  }))

  return (
    <Snackbar open={alertOpen} autoHideDuration={4000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={alertStatus} sx={{ width: '100%' }}>
        { messages[alertStatus] }
      </Alert>
    </Snackbar>
  )
})

export default DbAlert
