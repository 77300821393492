import { useState, useEffect, useRef, useContext } from 'react'
import { supabase } from 'supabaseClient'
import { useParams, useNavigate, useOutletContext } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { Context } from 'Store'
import moment from 'moment'
import slugify from 'slugify'
import { hash } from 'helpers/helpers'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import AddIcon from '@mui/icons-material/Add'
import SaveIcon from '@mui/icons-material/Save'

import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import InputAdornment from '@mui/material/InputAdornment'

function EditKilometers ({ item, close, closeReload }) {
  const { dbAlert } = useOutletContext()
  const [kilometers, setKilometers] = useState('')
  const [loading, setLoading] = useState(false)

  const kilometersChange = (ev, v) => setKilometers(ev.target.value)

  const saveKilometers = async () => {
    try {
      setLoading(true)

      const udata = {}
      if (kilometers != item.kilometers) udata.kilometers = kilometers

      let { data, error } = await supabase.from(item.api.type)
      .update(udata).match({ id: item.id })
      if (error) throw error

      dbAlert.current.showAlert('success')
      closeReload()
    } catch (error) {
      dbAlert.current.showAlert('error')
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setKilometers(item.kilometers)
  }, [])

  return (<>
    <DialogTitle>
      Edytuj kilometry
    </DialogTitle>
    <DialogContent>
      <Box sx={{ display: 'flex', pt: 1 }}>
        <TextField size="small" label="Licznik" value={kilometers} InputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
          endAdornment: <InputAdornment position="end">km</InputAdornment>
      }} onChange={kilometersChange} sx={{ flexGrow: 1, width: 250 }} />
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => close()}>anuluj</Button>
      <LoadingButton
        variant="contained"
        disableElevation
        endIcon={<SaveIcon />}
        loading={loading}
        loadingPosition="end"
        onClick={saveKilometers}
      >zapisz</LoadingButton>
    </DialogActions>
  </>)
}

export default EditKilometers
