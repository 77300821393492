import { useState, useEffect, useRef, useContext } from 'react'
import { supabase } from 'supabaseClient'
import { useParams, useNavigate, useOutletContext } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { Context } from 'Store'
import moment from 'moment'
import slugify from 'slugify'
import { hash } from 'helpers/helpers'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import AddIcon from '@mui/icons-material/Add'
import SaveIcon from '@mui/icons-material/Save'

import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import InputAdornment from '@mui/material/InputAdornment'

function EditComment ({ item, close, closeReload }) {
  const { dbAlert } = useOutletContext()
  const [comment, setComment] = useState('')
  const [loading, setLoading] = useState(false)

  const saveComment = async () => {
    try {
      setLoading(true)

      const udata = {}
      if (comment != item.comment) udata.comment = comment

      let { data, error } = await supabase.from(item.api.type)
      .update(udata).match({ id: item.id })
      if (error) throw error

      dbAlert.current.showAlert('success')
      closeReload()
    } catch (error) {
      dbAlert.current.showAlert('error')
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setComment(item.comment)
  }, [])

  return (<>
    <DialogTitle>
      Edytuj komentarz
    </DialogTitle>
    <DialogContent>
      <Box sx={{ pt: 1 }}>
        <TextField
          id="outlined-multiline-static"
          sx={{ width: 400 }}
          size="small"
          label="Komentarz"
          value={comment}
          onChange={v => setComment(v.target.value)}
          multiline
          rows={6}
        />
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => close()}>anuluj</Button>
      <LoadingButton
        variant="contained"
        disableElevation
        endIcon={<SaveIcon />}
        loading={loading}
        loadingPosition="end"
        onClick={saveComment}
      >zapisz</LoadingButton>
    </DialogActions>
  </>)
}

export default EditComment
