import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { supabase } from 'supabaseClient'
import moment from 'moment'
import { Context } from 'Store'
import { objectOfIds } from 'helpers/helpers'

import Loading from 'components/loading'
import Service from 'components/services/service'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

const Group = ({ group, activities }) => {
  return (
    <Grid container sx={{ pt: 3 }} alignItems="stretch" direction="row" spacing={2}>
      {group.map((item, i) => (
        <Grid item xs={4} key={i}>
          <Service item={item} activities={activities} />
        </Grid>
      ))}
    </Grid>
  )
}

function TruckServices () {
  const { truckId } = useParams()
  const [state, dispatch] = useContext(Context)
  const [services, setServices] = useState()

  const getServices = async () => {
    try {
      let { data: itemsData, error: itemsError } = await supabase
        .rpc('get_items').eq('truckid', truckId)
      if (itemsError) throw itemsError

      itemsData = itemsData.sort((a, b) =>
        moment(b.date).diff(moment(a.date), 'seconds'))
        .map(item => ({
          ...item,
          invoices: item.invoices.map(invoice => `${invoice.split('_')[0]}.pdf`)
        }))

      setServices(itemsData)
    } catch (error) { console.log(error) }
  }

  useEffect(() => {
    getServices()
  }, [])

  return (
    <Loading condition={services}>
      {services ? (<>
        <Divider />
        <Typography sx={{ pt: 3 }} variant="h4" component="h2" style={{ fontWeight: 'bold' }}>{services[0].plate}</Typography>
        <Group group={services} activities={objectOfIds(state.activities)} />
      </>) : ''}
    </Loading>
  )
}

export default TruckServices
