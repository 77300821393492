import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/pl'
import './index.css'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { plPL } from '@mui/material/locale'
import PrivateRoute from 'components/privateRoute'
import Store from './Store'
import { AuthProvider } from './Auth'
import App from './App'

import Login from 'routes/login'
import SignUp from 'routes/signup'
import Dashboard from 'routes/dashboard'
import TruckServices from 'routes/truckServices'
import AddService from 'routes/addService'
import AddRepair from 'routes/addRepair'
import ViewService from 'routes/viewService'
import ViewRepair from 'routes/viewRepair'
import Vehicles from 'routes/vehicles'

moment.locale('pl')
console.log = function () {}

const theme = createTheme({
  palette: {
    mode: 'light'
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      },
    },
  },
}, plPL)

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Store>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/signupadmin" element={<PrivateRoute><SignUp /></PrivateRoute>} />
              <Route path="/" element={<PrivateRoute><App /></PrivateRoute>}>
                <Route path="" element={<Dashboard />} />
                <Route path=":truckId" element={<TruckServices />} />
                <Route path="/add-service" element={<AddService />} />
                <Route path="/add-repair" element={<AddRepair />} />
                <Route path="/view-service/:serviceId" element={<ViewService />} />
                <Route path="/view-repair/:repairId" element={<ViewRepair />} />
                <Route path="/vehicles" element={<Vehicles />} />
              </Route>
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </Store>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
