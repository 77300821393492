import { useState, useEffect, useRef, useContext } from 'react'
import './App.css'
import { useAuth } from 'Auth'
import { Context } from './Store'
import { Outlet, Link, useNavigate } from 'react-router-dom'
import { supabase } from 'supabaseClient'

import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import ButtonGroup from '@mui/material/ButtonGroup'

import ConstructionIcon from '@mui/icons-material/Construction'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import LogoutIcon from '@mui/icons-material/Logout'

import DbAlert from 'components/dbAlert'

function App() {
  const { signOut, user } = useAuth()
  const [state, dispatch] = useContext(Context)
  const navigate = useNavigate()
  const dbAlert = useRef()
  const [signOutLoading, setSignOutLoading] = useState(false)

  const getVehicles = async () => {
    try {
      let { data, error } = await supabase.from('vehicles').select('*')
      if (error) throw error

      data.sort((a, b) => {
        let fa = a.plate.toLowerCase()
        let fb = b.plate.toLowerCase()

        if (fa < fb) return -1
        if (fa > fb) return 1
        return 0
      })

      const vehiclesArrayData = data.map(x => x.plate)

      dispatch({ type: 'set-vehicles', payload: data })
      dispatch({ type: 'set-vehicles-array', payload: vehiclesArrayData })
    } catch (error) {
      dbAlert.current.showAlert('error')
      console.log(error)
    }
  }
  const getActivities = async () => {
    try {
      let { data, error } = await supabase.from('activities').select('*')
      if (error) throw error

      dispatch({ type: 'set-activities', payload: data })
    } catch (error) {
      dbAlert.current.showAlert('error')
      console.log(error)
    }
  }

  async function handleSignOut() {
    try {
      setSignOutLoading(true)
      await signOut()
    } catch (error) {
      console.log(error)
      dbAlert.current.showAlert('error')
    } finally {
      setSignOutLoading(false)
    }
  }

  useEffect(() => {
    getVehicles()
    getActivities()
  }, [])

  return (
    <Container>
      <Box sx={{ height: 70, pb: 4, pt: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Link to="/"><img className="pointer" src="/bama.png" height="70px"></img></Link>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex' }}>
            <ButtonGroup variant="outlined">
              <Button sx={{ mr: 2 }} component={Link} to="/vehicles" startIcon={<LocalShippingIcon />}>Pojazdy</Button>
            </ButtonGroup>
            <ButtonGroup variant="contained" disableElevation>
              <Button component={Link} to="/add-service" startIcon={<AssignmentTurnedInIcon />} >Serwis</Button>
              <Button component={Link} to="/add-repair" startIcon={<ConstructionIcon />}>Naprawa</Button>
            </ButtonGroup>
          </Box>
          <Box sx={{ pl: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {user && user.user_metadata ? (
              <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{user.user_metadata ? user.user_metadata.name : ''}</Typography>
            ) : ''}
            <LoadingButton
              onClick={handleSignOut}
              endIcon={<LogoutIcon />}
              loading={signOutLoading}
              loadingPosition="end">
              Wyloguj
            </LoadingButton>
          </Box>
        </Box>
      </Box>

      <Outlet context={{ dbAlert }} />
      <DbAlert ref={dbAlert} />
    </Container>
  )
}

export default App
