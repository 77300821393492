import { useState, useEffect, useContext } from 'react'
import { Outlet, Link, useSearchParams, useLocation } from 'react-router-dom'
import { supabase } from 'supabaseClient'
import moment from 'moment'
import { Context } from 'Store'
import { objectOfIds } from 'helpers/helpers'

import Loading from 'components/loading'
import VehicleInfo from 'components/dashboard/vehicleInfo'
import Service from 'components/services/service'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'


// const dateRange = (start, end) => {
//   const fromD = moment(start)
//   const toD = moment(end)
//   const diff = toD.diff(fromD, 'days')
//   if (diff < 0) return []
//
//   return Array(diff+1).fill(null)
//     .map((x, i) => moment(toD).subtract(i, 'days').format('YYYY-MM-DD'))
// }
// const dashboardDates = dateRange(
//   moment().subtract(13, 'days').format('YYYY-MM-DD'),
//   moment().format('YYYY-MM-DD')
// )

// const Group = ({ group, activities }) => {
//   return (
//     <Grid container sx={{ pt: 3 }} alignItems="stretch" direction="row" spacing={2}>
//       {group.map((item, i) => (
//         <Grid item xs={12} key={i}>
//           <Service item={item} activities={activities} vehicleLink />
//         </Grid>
//       ))}
//     </Grid>
//   )
// }

function Dashboard () {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [state, dispatch] = useContext(Context)
  const [vehicles, setVehicles] = useState([])
  const [vehiclesArray, setVehiclesArray] = useState([])
  const [list, setList] = useState()
  // const [groups, setGroups] = useState()

  const [filterPlate, setFilterPlate] = useState(null)
  const [beforeDate, setBeforeDate] = useState(moment().subtract(27, 'days').format('YYYY-MM-DD'))

  const filterPlateChange = (v) => {
    setFilterPlate(v || null)
    if (v) setSearchParams({ plate: v })
    else setSearchParams({})
  }

  const getVehicles = async () => {
    try {
      let { data: vehiclesData, error: vehiclesError } = await supabase.from('vehicles').select('*')
      if (vehiclesError) throw vehiclesError

      const vehiclesArrayData = vehiclesData.map(x => x.plate).sort()
      // console.log(JSON.stringify(vehiclesArrayData, null, 2))

      if (vehiclesData) setVehicles(vehiclesData)
      if (vehiclesArrayData) setVehiclesArray(vehiclesArrayData)
    } catch (error) { console.log(error) }
  }
  const getItems = async (payload = {}) => {
    try {
      // setGroups(null)

      payload.ibeforedate = null

      if (!payload.iplate) payload.ifromdate = beforeDate

      console.log(beforeDate)
      console.log(JSON.stringify(payload, null, 2))
      let { data: servicesData, error: servicesError } = await supabase.rpc('get_services', payload)
      if (servicesError) throw servicesError

      // console.log(JSON.stringify(servicesData, null, 2))

      let { data: repairsData, error: repairsError } = await supabase.rpc('get_repairs', payload)
      if (repairsError) throw repairsError
      // console.log(JSON.stringify(repairsData, null, 2))

      const data = servicesData.concat(repairsData).sort((a, b) =>
        moment(b.date).diff(moment(a.date), 'seconds'))

        // console.log(JSON.stringify(data, null, 2)) uncomment this
      // const dateGroups = data.reduce(function (r, a) {
      //   const date = moment(a.date).format('YYYY-MM-DD')
      //   r[date] = r[date] || []
      //   r[date].push(a)
      //   return r
      // }, Object.create(null))

      // if (dateGroups) setGroups(dateGroups)
      if (data) setList(data)
    } catch (error) { console.log(error) }
  }

  const getMoreItems = async () => {
    const payload = {
      iplate: null,
      ifromdate: moment(beforeDate).subtract(13, 'days').format('YYYY-MM-DD'),
      ibeforedate: beforeDate
    }

    let { data: servicesData, error: servicesError } = await supabase.rpc('get_services', payload)
    if (servicesError) throw servicesError

    // console.log(JSON.stringify(servicesData, null, 2))

    let { data: repairsData, error: repairsError } = await supabase.rpc('get_repairs', payload)
    if (repairsError) throw repairsError
    // console.log(JSON.stringify(repairsData, null, 2))

    setBeforeDate(payload.ifromdate)
    const data = servicesData.concat(repairsData).sort((a, b) =>
      moment(b.date).diff(moment(a.date), 'seconds'))

    if (data) setList(list.concat(data))
  }

  useEffect(() => {
    getVehicles()
  }, [])
  useEffect(() => {
    setFilterPlate(searchParams.get('plate') || null)
    getItems({ iplate: searchParams.get('plate') || null })
  }, [location])

  return (<>
    <Box sx={{ pb: 4 }}>
      <Divider />
      <Box sx={{ display: 'flex', pt: 2, pl: 2 }}>
        <Typography variant="h4" component="h2" style={{ fontWeight: 'bold' }}>
          Naprawy i serwisy
        </Typography>
        <Typography sx={{ pl: 1.8, fontWeight: 'bold' }} color="text.secondary">
          {filterPlate ? '(historia pojazdu)' : '(ostatni miesiąc)'}
        </Typography>
      </Box>
      <Box sx={{ py: 2, pl: 2, display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', pr: 2 }}>Filtry:</Typography>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={vehiclesArray}
          value={filterPlate}
          onChange={(ev, v) => filterPlateChange(v)}
          sx={{ width: 180 }}
          renderInput={(params) => <TextField {...params} label="Rejestracja" size="small" />}
        />
      </Box>
      <Divider />
      {filterPlate ? <VehicleInfo plate={filterPlate} /> : ''}
    </Box>
    <Loading condition={list}>
      {list ? list.map((item, i) => (
        <Service item={item} activities={objectOfIds(state.activities)} vehicleLink key={i} />
      )) : ''}
      {!filterPlate ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', pt: 2, pb: 2 }}>
          <Box color="text.secondary" sx={{ textAlign: 'center', pb: 1 }}>Wyniki od: {moment(beforeDate).format('D MMMM YYYY')}</Box>
          <Button onClick={() => getMoreItems()}>Pokaż więcej...</Button>
        </Box>
      ) : ''}
      {/*groups && dashboardDates.map(date => date in groups ? (
        <Box sx={{ pb: 5 }} key={date}>
          <Divider sx={{
            fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.6)', textTransform: 'capitalize'
          }}>
            {moment(date).format('dddd DD.MM.YYYY')}
          </Divider>
          <Group group={groups[date]} activities={objectOfIds(state.activities)} />
        </Box>
      ) : '')*/}
    </Loading>
  </>)
}

export default Dashboard
