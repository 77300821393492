import { useState, useEffect, useRef } from 'react'
import { useOutletContext } from 'react-router-dom'
import { supabase } from 'supabaseClient'
import moment from 'moment'

import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import InputAdornment from '@mui/material/InputAdornment'
import AdapterMoment from '@mui/lab/AdapterMoment'
import TextField from '@mui/material/TextField'
import { vehicleTypes } from 'components/common/vehicleTypes'

import ConfirmEdit from 'components/common/confirmEdit'

function VehicleInfo({ plate }) {
  const { dbAlert } = useOutletContext()
  const [vehicle, setVehicle] = useState(null)
  const [vehicleType, setVehicleType] = useState('')
  const [adr, setAdr] = useState(false)
  const [sk, setSk] = useState(false)
  const [skDate, setSkDate] = useState(null)

  const vehicleTypeEdit = useRef()
  const adrEdit = useRef()
  const skEdit = useRef()
  const skDateEdit = useRef()

  const handleTypeChange = ev => {
    if (vehicle.type) return
    vehicleTypeEdit.current.confirm(ev.target.value)
  }
  const adrChange = ev => {
    if (vehicle.type == 'trailer') return
    adrEdit.current.confirm(ev.target.checked)
  }
  const skChange = ev => {
    if (vehicle.type == 'trailer') return
    skEdit.current.confirm(ev.target.checked)
  }

  const saveSkDate = async (date) => {
    try {
      setSkDate(date)

      const { data, error } = await supabase
        .from('vehicles')
        .update({ 'sk_date': date })
        .match({ id: vehicle.id })
      if (error) throw error

      skDateEdit.current.close()
      dbAlert.current.showAlert('success')
    } catch (error) {
      console.log(error)
      dbAlert.current.showAlert('error')
    }
  }

  const saveSk = async (checked) => {
    try {
      setSk(checked)
      if (!checked) setSkDate(null)

      const { data, error } = await supabase
        .from('vehicles')
        .update({
          'sk_device': checked,
          'sk_date': !checked ? null : undefined
        }).match({ id: vehicle.id })
      if (error) throw error

      skEdit.current.close()
      dbAlert.current.showAlert('success')
    } catch (error) {
      console.log(error)
      dbAlert.current.showAlert('error')
    }
  }

  const saveAdr = async (checked) => {
    try {
      setAdr(checked)

      const { data, error } = await supabase
        .from('vehicles')
        .update({ adr: checked })
        .match({ id: vehicle.id })
      if (error) throw error

      adrEdit.current.close()
      dbAlert.current.showAlert('success')
    } catch (error) {
      console.log(error)
      dbAlert.current.showAlert('error')
    }
  }

  const saveVehicleType = async (type) => {
    try {
      setVehicleType(type)

      const { data, error } = await supabase
        .from('vehicles')
        .update({ type: type })
        .match({ id: vehicle.id })
      if (error) throw error

      vehicleTypeEdit.current.close()
      dbAlert.current.showAlert('success')
    } catch (error) {
      console.log(error)
      dbAlert.current.showAlert('error')
    }
  }

  const getVehicle = async () => {
    try {
      let { data, error } = await supabase.from('vehicles').select('*').eq('plate', plate)
      if (error) throw error
      if (data.length < 1) throw new Error('no vehicle')

      const vehicleData = data[0]
      setVehicle(vehicleData)
      setVehicleType(vehicleData.type || '')
      setAdr(vehicleData.adr || false)
      setSk(vehicleData['sk_device'] || false)
      setSkDate(vehicleData['sk_date'] || null)
    } catch (error) { console.log(error) }
  }

  useEffect(() => {
    getVehicle()
  }, [plate])

  return vehicle ? (<>
    <Box className="card-footer" sx={{ py: 2, display: 'flex', alignItems: 'center' }}>
      <Box sx={{ textAlign: 'center', pl: 2, pr: 3 }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>{vehicle.kind == 'truck' ? 'Ciągnik' : 'Naczepa'}</Typography>
        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>{vehicle.plate}</Typography>
      </Box>
      <FormControl size="small" sx={{ width: 160 }}>
        <InputLabel id="demo-simple-select-label">Typ {vehicle.kind == 'truck' ? 'ciągnika' : 'naczepy'}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={vehicleType}
          onChange={handleTypeChange}
          inputProps={{
            readOnly: !!vehicle.type
          }}
          label={`Typ ${vehicle.kind == 'truck' ? 'ciągnika' : 'naczepy'}`}
        >
          {Object.keys(vehicleTypes[vehicle.kind]).map(type => (
            <MenuItem value={type} key={type}>{vehicleTypes[vehicle.kind][type]}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {vehicle.kind == 'truck' ? (<>
        <FormControlLabel sx={{ ml: 2, mr: 0 }} control={
          <Checkbox checked={adr} onChange={adrChange} />
        } label="ADR" />

        <FormControlLabel sx={{ ml: 'auto', mr: 3 }} control={
          <Checkbox checked={sk} onChange={skChange} />
        } label="Urządzenie na SK" />

        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Pobyt na SK"
            mask="__.__.____"
            value={skDate}
            onChange={(newValue) => skDateEdit.current.confirm(newValue.format('YYYY-MM-DD'))}
            renderInput={(params) => <TextField size="small" sx={{ width: 160, mr: 2 }} autoComplete="off" {...params} />}
          />
        </LocalizationProvider>
      </>) : ''}

      <ConfirmEdit onConfirm={saveVehicleType} ref={vehicleTypeEdit} />
      <ConfirmEdit onConfirm={saveAdr} ref={adrEdit} />
      <ConfirmEdit onConfirm={saveSk} ref={skEdit} />
      <ConfirmEdit onConfirm={saveSkDate} ref={skDateEdit} />
    </Box>
  </>) : ''
}

export default VehicleInfo
