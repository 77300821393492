import { createContext, useReducer } from 'react'

const initialState = {
  vehicles: [],
  vehiclesArray: [],
  activities: [],
}

function reducer(state, action) {
  switch (action.type) {
    case "set-vehicles":
      return {
        ...state,
        vehicles: action.payload
      }
    case "set-vehicles-array":
      return {
        ...state,
        vehiclesArray: action.payload
      }
    case "set-activities":
      return {
        ...state,
        activities: action.payload
      }
    default:
      return state
  }
}

export const Context = createContext()

function Store ({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <Context.Provider value={[state, dispatch]}>
      {children}
    </Context.Provider>
  )
}

export default Store
