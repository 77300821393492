import { useState, useEffect, useRef } from 'react'
import { useOutletContext, useSearchParams, useLocation } from 'react-router-dom'
import { supabase } from 'supabaseClient'
import moment from 'moment'
import slugify from 'slugify'

import Loading from 'components/loading'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import LoadingButton from '@mui/lab/LoadingButton'
import { DataGrid } from '@mui/x-data-grid'
import AddIcon from '@mui/icons-material/Add'
import { vehicleTypes } from 'components/common/vehicleTypes'

const columns = [
  // { field: 'id', headerName: 'ID', width: 80 },
  { field: 'plate', headerName: 'Rejestracja', width: 160 },
  { field: 'vin', headerName: 'VIN', width: 200 },
  { field: 'type', headerName: 'Typ', width: 200 },
  // { field: 'brand', headerName: 'Marka', width: 130 }
]
const vehicleKinds = ['truck', 'trailer']

function Vehicles () {
  const { dbAlert } = useOutletContext()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const [kind, setKind] = useState(vehicleKinds[0])
  const [plate, setPlate] = useState('')
  const [brand, setBrand] = useState('')
  const [loading, setLoading] = useState(false)

  const [brands, setBrands] = useState([])
  const [vehicles, setVehicles] = useState([])

  const plateChange = (ev) => setPlate(ev.target.value.toUpperCase())
  const brandChange = (ev) => setBrand(ev.target.value)

  const addTruck = async () => {
    try {
      setLoading(true)

      let { data, error } = await supabase.from('trucks').insert({
        plate: plate,
        brand: brand
      })
      if (error) throw error

      dbAlert.current.showAlert('success')
      getVehicles()
    } catch (error) {
      dbAlert.current.showAlert('error')
    } finally {
      setLoading(false)
    }
  }

  const filterKindChange = ev => {
    const v = ev.target.value
    if (v && v !== vehicleKinds[0]) setSearchParams({ kind: v })
    else setSearchParams({})
  }

  const getBrands = async () => {
    try {
      let { data: brandsData, error: brandsError } = await supabase.from('trucks_brands').select('*')
      if (brandsError) throw brandsError

      if (brandsData) setBrands(brandsData)
    } catch (error) { console.log(error) }
  }

  const getVehicles = async (vehicleKind) => {
    try {
      let { data: vehiclesData, error: vehiclesError } = await supabase.rpc(`get_${vehicleKind}s`)
      if (vehiclesError) throw vehiclesError

      vehiclesData = vehiclesData.map(x => ({
        ...x,
        type: vehicleTypes[vehicleKind][x.type] ? vehicleTypes[vehicleKind][x.type] : '-- brak danych --'
      }))

      if (vehiclesData) setVehicles(vehiclesData)
    } catch (error) { console.log(error) }
  }

  useEffect(() => {
    // getBrands()
  }, [])
  useEffect(() => {
    const paramKind = searchParams.get('kind')
    if (paramKind === null || vehicleKinds.includes(paramKind)) {
      setKind(paramKind ? paramKind : vehicleKinds[0])
      getVehicles(paramKind ? paramKind : vehicleKinds[0])
    } else setSearchParams({})
  }, [location])

  return (<>
    <Divider />
    <Typography sx={{ pt: 2, pl: 2 }} variant="h4" component="h2" style={{ fontWeight: 'bold' }}>Pojazdy</Typography>
    <Box sx={{ py: 2, pl: 2, display: 'flex', alignItems: 'center' }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', pr: 2 }}>Filtry:</Typography>
      <FormControl sx={{ width: 130, mr: 1 }} size="small">
        <InputLabel id="demo-simple-select-label">Typ</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={kind}
          label="Typ"
          onChange={filterKindChange}
        >
          <MenuItem value={'truck'}>Ciągniki</MenuItem>
          <MenuItem value={'trailer'}>Naczepy</MenuItem>
        </Select>
      </FormControl>
      {/*<TextField
        sx={{ width: 130, mr: 1 }}
        label="Rejestracja"
        variant="outlined"
        size="small"
        value={plate}
        onChange={plateChange}
      />
      <FormControl sx={{ width: 130, mr: 1 }} size="small">
        <InputLabel id="demo-simple-select-label">Marka</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          value={brand}
          label="Marka"
          onChange={brandChange}
        >
          {brands.map(brand => (
            <MenuItem value={brand.id} key={brand.id}>{brand.brand}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <LoadingButton
        variant="contained"
        endIcon={<AddIcon />}
        loadingPosition="end"
        loading={loading}
        onClick={addTruck}
        disableElevation
      >Dodaj</LoadingButton>*/}
    </Box>
    <Divider />
    <Loading condition={vehicles}>
      {vehicles && vehicles.length === 0 ? (
        <Box sx={{ fontWeight: 'bold', textAlign: 'center', pt: 4 }}>Brak wyników</Box>
      ) : ''}
      {vehicles.length ? (<>
        <DataGrid
          sx={{ mt: 3, mb: 4 }}
          rows={vehicles}
          columns={columns}
          autoHeight
          onRowDoubleClick={(params, event) => {
            window.open(`/?plate=${params.row.plate}`, '_blank').focus()
          }}
        />
      </>) : ''}
    </Loading>
  </>)
}

export default Vehicles
