import { useEffect, useState } from 'react'
import { useAuth } from 'Auth'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

function SignUp () {
  const { signUp, update, signIn, user } = useAuth()
  const [email, setEmail] = useState()
  const [pass, setPass] = useState()
  const [name, setName] = useState()

  async function handleLogin() {
    const { error } = await signIn({
      email: email,
      password: pass
    })
    if (error) {
      alert(JSON.stringify(error))
    } else {
      alert('logged in')
      setMeta()
    }
  }

  async function handleSubmit() {
    const { error } = await signUp({
      email: email,
      password: pass
    }, {
      data: {
        name: name
      }
    })
    if (error) {
      alert(JSON.stringify(error))
    } else {
      alert('created account')
      setMeta()
      // handleLogin()
    }
  }

  const setMeta = () => update({
    data: {
      name: name
    }
  }).then(res => {
    alert('success')
  }).catch(err => alert(JSON.stringify(err)))

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: 300, gap: 1, pl: 2, alignItems: 'center', m: 'auto', mt: 6 }}>
        {user && user.user_metadata ? (
          <b>as: {user.user_metadata ? user.user_metadata.name : ''}</b>
        ) : ''}
        <TextField variant="outlined" label="Email" size="small" sx={{ mt: 2 }} value={email} onChange={(e) => setEmail(e.target.value)} />
        <TextField variant="outlined" label="Password" size="small" value={pass} onChange={(e) => setPass(e.target.value)} />
        <TextField variant="outlined" label="Name" size="small" value={name} onChange={(e) => setName(e.target.value)} />
        <Button onClick={handleSubmit}>Sign up</Button>
        <Button onClick={setMeta}>Set meta</Button>
      </Box>
    </Box>
  )
}

export default SignUp
