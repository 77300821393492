import { Link } from 'react-router-dom'
import moment from 'moment'

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardActionArea from '@mui/material/CardActionArea'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import ConstructionIcon from '@mui/icons-material/Construction'

function Service ({ item, activities, vehicleLink }) {
  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardActionArea component={Link} to={item.activities ? `/view-service/${item.id}` : `/view-repair/${item.id}`} sx={{ display: 'flex', alignItems: 'stretch', p: 2 }}>
        <Box className="border-right" sx={{ minHeight: 60, pr: 2 }}>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ fontSize: 21, fontWeight: 'bold' }}>
              {vehicleLink ? (<>
                {item.plate}
                {/*<Link className="link" to={`/?plate=${item.plate}`}></Link>*/}
              </>) : item.plate}
            </Typography>
            {/*}<Chip label={item.brand} size="small" sx={{ ml: 1, bgcolor: item.bcolor, color: 'white', fontWeight: 'bold' }} />*/}
          </Box>
          <Typography sx={{ fontSize: 18 }} color="text.secondary">
            {item.kilometers} km
          </Typography>
        </Box>

        {item.title ? (
          <Box className="border-right" sx={{ px: 2, display: 'flex', alignItems: 'center', justifyContent: 'start', width: '25%' }}>
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
              {item.title}
            </Typography>
          </Box>
        ) : ''}
        {item.activities ? (
          <Box className="border-right" sx={{ px: 2, width: '25%' }}>
            <ul className="list-s">
              {item.activities.map(activity => activity in activities ? (
                <li key={activity}>{activities[activity].title}</li>
              ) : '')}
            </ul>
          </Box>
        ) : ''}

        {item.comment ? (
          <Box className="border-right" sx={{ px: 2, width: '20%' }}>
            <Typography sx={{ fontSize: 15 }} color="text.secondary">
              Komentarz:
            </Typography>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
              {item.comment}
            </Typography>
          </Box>
        ) : ''}

        {item.invoices && item.invoices.length ? (
          <Box sx={{ flexGrow: 1, px: 2 }}>
            <Typography sx={{ fontSize: 15, pb: 1 }} color="text.secondary">
              Faktury:
            </Typography>
            <Box sx={{ display: 'flex' }}>
              {item.invoices.map((invoice, i) => (
                <Box sx={{ pr: 1 }} key={i}><img src="pdf.png" height="20px"></img></Box>
              ))}
            </Box>
          </Box>
        ) : ''}

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'space-between', flexGrow: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: 16, color: 'white', backgroundColor: item.activities ? '#15B661' : (item.closed ? '#15B661' : '#DE3163'), borderRadius: '10px', pr: 1, height: 30 }}>
            {item.activities ? (
              <AssignmentTurnedInIcon sx={{ fontSize: 18, mx: 1 }} />
            ) : (
              <ConstructionIcon sx={{ fontSize: 18, mx: 1 }} />
            )}
            <span>{item.activities ? 'serwis' : 'naprawa'}</span>
          </Box>
          <Box sx={{ fontSize: 16 , fontWeight: 'bold' }}>{moment(item.date).format('D MMMM YYYY')}</Box>
        </Box>
      </CardActionArea>
    </Card>
  )
}

export default Service
