import { useState, useRef, useEffect } from 'react'
import { supabase } from 'supabaseClient'
import { useOutletContext } from 'react-router-dom'
import moment from 'moment'
import slugify from 'slugify'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'

import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import ReceiptIcon from '@mui/icons-material/Receipt'
import AddIcon from '@mui/icons-material/Add'

import ConfirmDelete from 'components/common/confirmDelete'
import AddInvoice from 'components/common/addInvoice'
import EditExternalInvoice from 'components/repair/editExternalInvoice'

const parseInvoice = async (invoice, plate, date) => {
  let url = ''
  let { data: fileData, error: fileError } = await supabase.storage.from('invoices').download(invoice.path)
  if (!fileError) url = URL.createObjectURL(fileData)
  else console.log(fileError)

  return {
    ...invoice,
    path: invoice.path,
    name: `${invoice.path.split('.')[0]}.pdf`,
    url: url,
    download: slugify(`${plate}_${moment(date).format('YYYY-MM-DD')}_${invoice.path.split('.')[0]}.pdf`)
  }
}

export const parseInvoices = (invoices, plate, date) => {
  return Promise.all(
    invoices.map(invoice =>
      parseInvoice(invoice, plate, date))
  )
}

function Invoices ({ item, getItem }) {
  const { dbAlert } = useOutletContext()

  const [invoiceDialog, setInvoiceDialog] = useState(false)
  const [editedInvoice, setEditedInvoice] = useState(null)
  const [externalDialog, setExternalDialog] = useState(false)
  const invoiceDeletion = useRef()

  const openInvoice = async (invoice) => {
    const { signedURL, error } = await supabase.storage.from('invoices').createSignedUrl(invoice, 60)
    window.open(signedURL, '_blank')
  }

  const closeInvoiceDialog = () => {
    setEditedInvoice(null)
    setInvoiceDialog(false)
  }
  const closeInvoiceDialogReload = () => {
    getItem()
    closeInvoiceDialog()
  }
  const editInvoice = (invoice) => {
    setEditedInvoice(invoice)
    setInvoiceDialog(true)
  }

  const closeExternalDialog = () => setExternalDialog(false)
  const closeExternalDialogReload = () => {
    getItem()
    closeExternalDialog()
  }

  const deleteInvoice = async (invoiceId) => {
    try {
      const { data, error } = await supabase
        .from(item.api.invoices)
        .update({ deleted: true })
        .match({ id: invoiceId })
      if (error) throw error

      getItem()
      invoiceDeletion.current.close()
      dbAlert.current.showAlert('success')
    } catch (error) {
      console.log(error)
      dbAlert.current.showAlert('error')
    }
  }

  return (<>
    <Box sx={{ fontWeight: 'bold', fontSize: 18, my: 3 }}>
      <Box className="card-header">
        <Typography sx={{ fontSize: 20, py: 1, pl: 2, fontWeight: 'bold' }}>
          Faktury:
        </Typography>
        <Divider />
      </Box>
      {!item.invoices.length ? (
        <Box>
          <Typography sx={{ fontSize: 16, textAlign: 'center', py: 2 }} color="text.secondary">
            Brak faktur...
          </Typography>
          <Divider />
        </Box>
      ) : ''}
      {item.invoices.map((invoice, i) => {
        return (
          <div key={i}>
            <Box sx={{ lineHeight: '30px', display: 'flex', alignItems: 'center', py: 0.7, pl: 1 }}>
              <a className="invoice-download" href={invoice.url} download={invoice.download}>
                <img src="/pdf.png" height="25px"></img>
              </a>
              <Box sx={{ pl: 2, flexGrow: 1 }}>
                <span className="link" onClick={() => openInvoice(invoice.path)}>{invoice.title}</span>
              </Box>
              <Box>
                {invoice.price.toFixed(2).replace('.', ',')} zł
              </Box>
              <IconButton sx={{ ml: 2 }} color="primary" onClick={() => editInvoice(invoice)}><EditIcon /></IconButton>
              <IconButton color="error" onClick={() => invoiceDeletion.current.confirm(invoice.id)}><CloseIcon /></IconButton>
            </Box>
            <Divider />
          </div>
        )
      })}
      <Box className="card-footer">
        <Box sx={{ display: 'flex', alignItems: 'center', py: 1.6 }}>
          <ReceiptIcon sx={{ fontSize: 30, pl: 1 }} />
          <Box sx={{ pl: 2 }}>Suma:</Box>
          <Box sx={{ flexGrow: 1, textAlign: 'end' }}>
            {item.invoices.reduce((a, b) => a + b.price, 0).toFixed(2).replace('.', ',')} zł
          </Box>
          <Box sx={{ width: 80, ml: 2, textAlign: 'center' }}>
            <Button variant="contained" disableElevation size="small" sx={{ width: '80%' }} onClick={() => setInvoiceDialog(true)}><AddIcon /></Button>
          </Box>
        </Box>
        {item.api.type == 'repairs' && item.external ? (<>
          <Divider />
          <Box sx={{ py: 2, display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontSize: 18, fontWeight: 'bold', flexGrow: 1, ml: 2 }}>
              Faktura:
            </Typography>
            <TextField size="small" label="Numer faktury" value={item.invoice_nr || ''} InputProps={{
                readOnly: true
            }} sx={{ width: 200, mr: 1 }} />
            <TextField size="small" label="Cena" value={item.invoice_price || ''} InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                readOnly: true,
                endAdornment: <InputAdornment position="end">zł</InputAdornment>
            }} sx={{ width: 110 }} />
            <IconButton sx={{ mx: 1 }} color="primary" onClick={() => setExternalDialog(true)}><EditIcon /></IconButton>
          </Box>
        </>) : ''}
      </Box>
    </Box>

    <ConfirmDelete onConfirm={deleteInvoice} ref={invoiceDeletion} />

    <Dialog open={invoiceDialog}>
      {invoiceDialog ? (
        <AddInvoice item={item} editedInvoice={editedInvoice} close={closeInvoiceDialog} closeReload={closeInvoiceDialogReload} />
      ) : ''}
    </Dialog>

    <Dialog open={externalDialog}>
      {externalDialog ? (
        <EditExternalInvoice item={item} close={closeExternalDialog} closeReload={closeExternalDialogReload} />
      ) : ''}
    </Dialog>
  </>)
}

export default Invoices
