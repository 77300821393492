import { useState, useEffect, useRef, useContext } from 'react'
import { supabase } from 'supabaseClient'
import { useParams, useNavigate, Link, useOutletContext } from 'react-router-dom'
import moment from 'moment'
import slugify from 'slugify'
import { Context } from 'Store'
import { objectOfIds } from 'helpers/helpers'
import { parseInvoices } from 'components/common/invoices'

import Loading from 'components/loading'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

import Invoices from 'components/common/invoices'

import ConfirmDelete from 'components/common/confirmDelete'
import EditKilometers from 'components/common/editKilometers'
import EditComment from 'components/common/editComment'
import { parseVehicleType } from 'components/common/vehicleTypes'

const Activities = ({ item, activities }) => {
  return (
    <ul className="list">
      {item.activities.map(activity => activity in activities ? (
        <li key={activity}>{activities[activity].title}</li>
      ) : '')}
    </ul>
  )
}

function ViewService () {
  const [state, dispatch] = useContext(Context)
  const navigate = useNavigate()
  const { serviceId } = useParams()
  const { dbAlert } = useOutletContext()

  const [item, setItem] = useState()

  const [kilometersDialog, setKilometersDialog] = useState(false)
  const [commentDialog, setCommentDialog] = useState(false)

  const serviceDeletion = useRef()

  const openInvoice = async (invoice) => {
    console.log(invoice)
    const { signedURL, error } = await supabase.storage.from('invoices').createSignedUrl(invoice, 60)
    window.open(signedURL, '_blank')
  }

  const deleteService = async () => {
    try {
      const { data, error } = await supabase
        .from('services')
        .update({ deleted: true })
        .match({ id: serviceId })
      if (error) throw error

      serviceDeletion.current.close()
      dbAlert.current.showAlert('success')
      navigate(-1)
    } catch (error) {
      console.log(error)
      dbAlert.current.showAlert('error')
    }
  }

  const closeKilometersDialog = () => setKilometersDialog(false)
  const closeKilometersDialogReload = () => {
    getItem()
    closeKilometersDialog()
  }
  const closeCommentDialog = () => setCommentDialog(false)
  const closeCommentDialogReload = () => {
    getItem()
    closeCommentDialog()
  }

  const getItem = async () => {
    try {
      let { data: itemData, error: itemError } = await supabase.rpc('get_service', { iid: serviceId })
      if (itemError) throw itemError

      itemData = itemData[0]
      itemData.api = {
        type: 'services',
        invoices: 'services_invoices',
        addInvoice: 'add_service_invoice'
      }
      itemData.invoices = await parseInvoices(itemData.invoices, itemData.plate, itemData.date)
      console.log(JSON.stringify(itemData, null, 2))

      if (itemData) setItem(itemData)
    } catch (error) { console.log(error) }
  }

  useEffect(() => {
    getItem()
  }, [])

  return (
    <Loading condition={item}>
      {item ? (<>
        <Divider />
        <Typography sx={{ pt: 3 }} variant="h4" component="h2" style={{ fontWeight: 'bold' }}>Serwis #{serviceId}</Typography>
        <Paper variant="outlined" sx={{ p: 3, mt: 3, mb: 10, width: '60%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex' }}>
              <Link className="link" to={`/?plate=${item.plate}`}>
                <Typography variant="h4" component="h2" style={{ fontWeight: 'bold' }}>{item.plate}</Typography>
              </Link>
              <Chip label={parseVehicleType(item.vehiclekind, item.vehicletype)} variant="outlined" size="small" sx={{ ml: 1, fontWeight: 'bold', fontSize: 16 }} />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
            <Typography sx={{ fontSize: 30 }} color="text.secondary">
              {item.kilometers} km
            </Typography>
            <IconButton sx={{ ml: 1 }} color="primary" onClick={() => setKilometersDialog(true)}><EditIcon /></IconButton>
          </Box>

          <Box sx={{ pb: 2 }}>
            <Activities item={item} activities={objectOfIds(state.activities)} />
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontSize: 20 }} color="text.secondary">
              Komentarz:
            </Typography>
            <IconButton sx={{ ml: 1 }} color="primary" onClick={() => setCommentDialog(true)}><EditIcon /></IconButton>
          </Box>
          <Typography sx={{ fontSize: 20, whiteSpace: 'pre-wrap' }}>
            {item.comment ? item.comment : '- - -'}
          </Typography>

          <Invoices item={item} getItem={getItem} />

          <Box>
            <Divider />
            <Box sx={{ display: 'flex', alignItems: 'center', pt: 3 }}>
              <Box sx={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center', flexGrow: 1, pr: 2 }}>{moment(item.date).format('D MMMM YYYY')}</Box>
              <Button
                color="error" disableElevation startIcon={<DeleteIcon />} onClick={() => serviceDeletion.current.confirm()}>usuń</Button>
              {/*<Button component={Link} to={`/view-service/${item.id}`} disableElevation startIcon={<EditIcon />}>Edytuj</Button>*/}
            </Box>
          </Box>
        </Paper>
      </>) : ''}

      <ConfirmDelete onConfirm={deleteService} ref={serviceDeletion} />

      <Dialog open={kilometersDialog}>
        {kilometersDialog ? (
          <EditKilometers item={item} close={closeKilometersDialog} closeReload={closeKilometersDialogReload} />
        ) : ''}
      </Dialog>

      <Dialog open={commentDialog}>
        {commentDialog ? (
          <EditComment item={item} close={closeCommentDialog} closeReload={closeCommentDialogReload} />
        ) : ''}
      </Dialog>
    </Loading>
  )
}

export default ViewService
