import { useState, useEffect, useRef } from 'react'
import { supabase } from 'supabaseClient'
import { useParams, useNavigate, Link, useOutletContext } from 'react-router-dom'
import moment from 'moment'
import { objectOfIds } from 'helpers/helpers'
import { parseInvoices } from 'components/common/invoices'

import Loading from 'components/loading'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import InputAdornment from '@mui/material/InputAdornment'
import Checkbox from '@mui/material/Checkbox'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import DescriptionIcon from '@mui/icons-material/Description'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import ReceiptIcon from '@mui/icons-material/Receipt'

import Invoices from 'components/common/invoices'

import ConfirmDelete from 'components/common/confirmDelete'
import ConfirmEdit from 'components/common/confirmEdit'
import EditKilometers from 'components/common/editKilometers'
import EditTitle from 'components/repair/editTitle'
import EditComment from 'components/common/editComment'
import { parseVehicleType } from 'components/common/vehicleTypes'

const Activities = ({ item, activities }) => {
  return (
    <ul className="list">
      {item.activities.map(activity => activity in activities ? (
        <li key={activity}>{activities[activity].title}</li>
      ) : '')}
    </ul>
  )
}

function ViewRepair () {
  const navigate = useNavigate()
  const { repairId } = useParams()
  const { dbAlert } = useOutletContext()

  const [item, setItem] = useState()

  const [kilometersDialog, setKilometersDialog] = useState(false)
  const [titleDialog, setTitleDialog] = useState(false)
  const [commentDialog, setCommentDialog] = useState(false)

  const closedEdit = useRef()
  const repairDeletion = useRef()

  const toggleClosed = async () => {
    try {
      const { data, error } = await supabase
        .from('repairs')
        .update({ closed: !item.closed })
        .match({ id: repairId })
      if (error) throw error

      getItem()
      closedEdit.current.close()
      dbAlert.current.showAlert('success')
    } catch (error) {
      console.log(error)
      dbAlert.current.showAlert('error')
    }
  }

  const deleteRepair = async () => {
    try {
      const { data, error } = await supabase
        .from('repairs')
        .update({ deleted: true })
        .match({ id: repairId })
      if (error) throw error

      repairDeletion.current.close()
      dbAlert.current.showAlert('success')
      navigate(-1)
    } catch (error) {
      console.log(error)
      dbAlert.current.showAlert('error')
    }
  }

  const closeKilometersDialog = () => setKilometersDialog(false)
  const closeKilometersDialogReload = () => {
    getItem()
    closeKilometersDialog()
  }
  const closeTitleDialog = () => setTitleDialog(false)
  const closeTitleDialogReload = () => {
    getItem()
    closeTitleDialog()
  }
  const closeCommentDialog = () => setCommentDialog(false)
  const closeCommentDialogReload = () => {
    getItem()
    closeCommentDialog()
  }

  const getItem = async () => {
    try {
      let { data: itemData, error: itemError } = await supabase.rpc('get_repair', { iid: repairId })
      if (itemError) throw itemError

      itemData = itemData[0]
      itemData.api = {
        type: 'repairs',
        invoices: 'repairs_invoices',
        addInvoice: 'add_repair_invoice'
      }
      itemData.invoices = await parseInvoices(itemData.invoices, itemData.plate, itemData.date)
      console.log(JSON.stringify(itemData, null, 2))

      if (itemData) setItem(itemData)
    } catch (error) { console.log(error) }
  }

  useEffect(() => {
    getItem()
  }, [])

  return (
    <Loading condition={item}>
      {item ? (<>
        <Divider />
        <Typography sx={{ pt: 3 }} variant="h4" component="h2" style={{ fontWeight: 'bold' }}>Zlecenie naprawy #{repairId}</Typography>
        <Paper variant="outlined" sx={{ p: 3, mt: 3, mb: 10, width: '60%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex' }}>
              <Link className="link" to={`/?plate=${item.plate}`}>
                <Typography variant="h4" component="h2" style={{ fontWeight: 'bold' }}>{item.plate}</Typography>
              </Link>
              <Chip label={parseVehicleType(item.vehiclekind, item.vehicletype)} variant="outlined" size="small" sx={{ ml: 1, fontWeight: 'bold', fontSize: 16 }} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: 20, color: 'white', backgroundColor: item.closed ? '#15B661' : '#DE3163', borderRadius: '10px', pr: 2, height: 40 }}>
              <Checkbox checked={item.closed} onChange={() => closedEdit.current.confirm()} sx={{ '& .MuiSvgIcon-root': { fill: 'white' } }} />
              <span>{item.closed ? 'zamknięte' : 'otwarte'}</span>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
            <Typography sx={{ fontSize: 30 }} color="text.secondary">
              {item.kilometers} km
            </Typography>
            <IconButton sx={{ ml: 1 }} color="primary" onClick={() => setKilometersDialog(true)}><EditIcon /></IconButton>
          </Box>

          <Box sx={{ pb: 2, display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>
              {item.title}
            </Typography>
            <IconButton sx={{ ml: 1 }} color="primary" onClick={() => setTitleDialog(true)}><EditIcon /></IconButton>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontSize: 20 }} color="text.secondary">
              Komentarz:
            </Typography>
            <IconButton sx={{ ml: 1 }} color="primary" onClick={() => setCommentDialog(true)}><EditIcon /></IconButton>
          </Box>
          <Typography sx={{ fontSize: 20, whiteSpace: 'pre-wrap' }}>
            {item.comment ? item.comment : '- - -'}
          </Typography>

          <Invoices item={item} getItem={getItem} />

          <Box>
            <Divider />
            <Box sx={{ display: 'flex', alignItems: 'center', pt: 3 }}>
              <Box sx={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center', flexGrow: 1, pr: 2 }}>{moment(item.date).format('D MMMM YYYY')}</Box>
              <Button
                color="error" disableElevation startIcon={<DeleteIcon />} onClick={() => repairDeletion.current.confirm()}>usuń</Button>
              {/*<Button component={Link} to={`/view-service/${item.id}`} disableElevation startIcon={<EditIcon />}>Edytuj</Button>*/}
            </Box>
          </Box>
        </Paper>
      </>) : ''}

      <ConfirmEdit onConfirm={toggleClosed} ref={closedEdit} />
      <ConfirmDelete onConfirm={deleteRepair} ref={repairDeletion} />

      <Dialog open={kilometersDialog}>
        {kilometersDialog ? (
          <EditKilometers item={item} close={closeKilometersDialog} closeReload={closeKilometersDialogReload} />
        ) : ''}
      </Dialog>

      <Dialog open={titleDialog}>
        {titleDialog ? (
          <EditTitle item={item} close={closeTitleDialog} closeReload={closeTitleDialogReload} />
        ) : ''}
      </Dialog>

      <Dialog open={commentDialog}>
        {commentDialog ? (
          <EditComment item={item} close={closeCommentDialog} closeReload={closeCommentDialogReload} />
        ) : ''}
      </Dialog>
    </Loading>
  )
}

export default ViewRepair
