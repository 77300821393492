export const vehicleTypes = {
  truck: {
    'low-deck': 'Low Deck',
    standard: 'Standard'
  },
  trailer: {
    mega: 'Mega',
    frigo: 'Chłodnia'
  }
}

export const parseVehicleType = (kind, type) => {
  console.log(kind, type)
  if (!kind || !type) return ''
  return `${kind === 'truck' ? 'Ciągnik' : ''}${kind === 'trailer' ? 'Naczepa' : ''} ${type ? vehicleTypes[kind][type] : ''}`
}
