import { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'Auth'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import LoginIcon from '@mui/icons-material/Login'

import DbAlert from 'components/dbAlert'

function Login () {
  const navigate = useNavigate()
  const { signIn } = useAuth()

  const dbAlert = useRef()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  async function handleLogin() {
    try {
      setLoading(true)
      if (email === '' || password === '') throw new Error('empty')

      const { error } = await signIn({ email, password })
      if (error) throw error

      navigate('/')
    } catch (error) {
      console.log(error)
      dbAlert.current.showAlert('error')
    } finally {
      setLoading(false)
    }
  }

  const keyPress = (ev) => {
    if (ev.key !== 'Enter') return
    handleLogin()
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '90vh' }}>
      <Paper variant="outlined" sx={{ p: 4, width: 300, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <img src="/bama.png" height="70px"></img>
        <TextField label="Email" variant="outlined" size="small" value={email} onChange={e => setEmail(e.target.value)} sx={{ width: '100%', mt: 6 }} />
        <TextField label="Hasło" type="password" variant="outlined" size="small" value={password} onChange={e => setPassword(e.target.value)} sx={{ width: '100%', mt: 3 }} onKeyDown={keyPress} />
        <LoadingButton
          sx={{ mt: 4 }}
          onClick={handleLogin}
          disableElevation
          disabled={email === '' || password === ''}
          endIcon={<LoginIcon />}
          loading={loading}
          loadingPosition="end"
          variant="contained">
          Zaloguj
        </LoadingButton>
      </Paper>

      <DbAlert ref={dbAlert} />
    </Box>
  )
}

export default Login
