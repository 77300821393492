import { useState, useEffect, useRef, useContext } from 'react'
import { supabase } from 'supabaseClient'
import { useParams, useNavigate, useOutletContext } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { Context } from 'Store'
import moment from 'moment'
import slugify from 'slugify'
import { hash } from 'helpers/helpers'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import AddIcon from '@mui/icons-material/Add'
import SaveIcon from '@mui/icons-material/Save'

import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import InputAdornment from '@mui/material/InputAdornment'

function AddInvoice ({ item, editedInvoice, close, closeReload }) {
  const { dbAlert } = useOutletContext()
  const [attachment, setAttachment] = useState()
  const [attachmentName, setAttachmentName] = useState('')
  const [price, setPrice] = useState('')
  const [loading, setLoading] = useState(false)
  const inputFile = useRef(null)

  const handleInputFile = ev => {
    if (ev.target.files && ev.target.files.length) setAttachment(ev.target.files[0])
    else {
      setAttachment()
      setAttachmentName('')
    }
  }
  const handleAttachmentName = (ev, v) => setAttachmentName(ev.target.value)
  const priceChange = (ev, v) => setPrice(ev.target.value)

  const addInvoice = async () => {
    try {
      setLoading(true)

      if (!attachment) throw new Error('add a file')

      const fileExt = attachment.name.split('.').pop()
      const filePath = slugify(`${hash()}${hash()}${hash()}${hash()}.${fileExt}`)

      // if (fileExt !== 'pdf')
      let { error: attachmentError } = await supabase.storage.from('invoices').upload(filePath, attachment)
      if (attachmentError) throw attachmentError

      let { data, error } = await supabase.rpc(item.api.addInvoice, {
        iid: item.id,
        iinvoice: filePath,
        ititle: attachmentName,
        iprice: price.replace(',', '.')
      })
      if (error) throw error
      if (data !== 1) throw new Error('not added')

      dbAlert.current.showAlert('success')
      closeReload()
    } catch (error) {
      dbAlert.current.showAlert('error')
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const saveInvoice = async () => {
    try {
      setLoading(true)

      const udata = {}
      if (attachmentName != editedInvoice.title) udata.title = attachmentName
      if (price != editedInvoice.price) udata.price = price.replace(',', '.')

      let { data, error } = await supabase.from(item.api.invoices)
      .update(udata).match({ id: editedInvoice.id })
      if (error) throw error

      dbAlert.current.showAlert('success')
      closeReload()
    } catch (error) {
      dbAlert.current.showAlert('error')
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const submit = () => {
    if (editedInvoice) saveInvoice()
    else addInvoice()
  }

  useEffect(() => {
    if (editedInvoice) {
      setAttachmentName(editedInvoice.title)
      setPrice(editedInvoice.price.toFixed(2).replace('.', ','))
    }
  }, [])

  return (<>
    <DialogTitle>
      {editedInvoice ? 'Edytuj fakturę' : 'Dodaj fakturę'}
    </DialogTitle>
    <DialogContent>
      <Box sx={{ display: 'flex', pt: 1 }}>
        <input
          style={{ display: 'none' }}
          ref={inputFile}
          onChange={handleInputFile}
          type="file"
          accept=".pdf"
        />
      <Button variant={attachment ? 'contained' : 'outlined'} disableElevation color="primary" disabled={!!editedInvoice} startIcon={<CloudUploadIcon />} sx={{ mr: 1 }} onClick={() => inputFile.current.click()}>
          Plik
        </Button>
        <TextField size="small" label="Nazwa" value={attachmentName} onChange={handleAttachmentName} sx={{ width: 350, mr: 1 }} />
        <TextField size="small" label="Cena" value={price} InputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
          endAdornment: <InputAdornment position="end">zł</InputAdornment>
        }} onChange={priceChange} sx={{ width: 110 }} />
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => close()}>anuluj</Button>
      <LoadingButton
        variant="contained"
        disableElevation
        endIcon={editedInvoice ? <SaveIcon /> : <AddIcon />}
        loading={loading}
        loadingPosition="end"
        disabled={attachmentName === '' || price === ''}
        onClick={submit}
      >{editedInvoice ? 'zapisz' : 'dodaj'}</LoadingButton>
    </DialogActions>
  </>)
}

export default AddInvoice
