import { useState, useEffect, useRef, useContext } from 'react'
import { supabase } from 'supabaseClient'
import { useParams, useNavigate, useOutletContext } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { Context } from 'Store'
import moment from 'moment'
import slugify from 'slugify'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import DatePicker from '@mui/lab/DatePicker'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import AddIcon from '@mui/icons-material/Add'

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import Chip from '@mui/material/Chip'

import LocalizationProvider from '@mui/lab/LocalizationProvider'
import InputAdornment from '@mui/material/InputAdornment'
import AdapterMoment from '@mui/lab/AdapterMoment'
import { parseVehicleType } from 'components/common/vehicleTypes'

function activitiesStyles(item, set, theme) {
  return {
    fontWeight:
      set.includes(item)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular
  }
}

function AddService () {
  const [state, dispatch] = useContext(Context)
  const theme = useTheme()
  const navigate = useNavigate()
  const params = useParams()
  const { dbAlert } = useOutletContext()

  const [plate, setPlate] = useState()
  const [kilometers, setKilometers] = useState('')
  const [comment, setComment] = useState('')
  const [activitiesSet, setActivitiesSet] = useState([])
  const [date, setDate] = useState(moment())
  const [loading, setLoading] = useState(false)

  const plateChange = (ev, v) => {
    if (!v) setActivitiesSet([])
    setPlate(v)
  }
  const activitiesSetChange = ev => {
    const { target: { value } } = ev
    setActivitiesSet(value)
  }
  const kilometersChange = (ev, v) => setKilometers(ev.target.value)

  const handleSubmit = async () => {
    try {
      setLoading(true)

      const payload = {
        ivehicle: plate ? plate.id : null,
        ikilometers: kilometers,
        iactivities: activitiesSet,
        icomment: comment,
        idate: date.isSame(moment(), 'day')
          ? moment().format('YYYY-MM-DD HH:mm:ss')
          : date.format('YYYY-MM-DD')
      }

      console.log(JSON.stringify(payload, null, 2))

      let { data, error } = await supabase.rpc('add_service', payload)
      if (error) throw error

      console.log(data)

      dbAlert.current.showAlert('success')
      navigate(`/view-service/${data}`)
    } catch (error) {
      dbAlert.current.showAlert('error')
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box>
      <Divider />
      <Typography sx={{ pt: 3 }} variant="h4" component="h2" style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
        Nowy serwis
        {plate ? (<>
          <Typography sx={{ fontWeight: 'bold', fontSize: 20, pl: 2 }} color="text.secondary">
            {plate ? `-> ${parseVehicleType(plate.kind, plate.type)}` : ''}
          </Typography>
        </>) : ''}
      </Typography>

      <Box sx={{ pt: 3, display: 'flex' }}>
        <Box sx={{ width: 400 }}>
          <Box sx={{ pb: 2, display: 'flex', alignItems: 'center' }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={state.vehicles.filter(x => x.kind && x.type)}
              getOptionLabel={(option) => option.plate}
              onChange={plateChange}
              sx={{ width: 300, pr: 1 }}
              renderInput={(params) => <TextField {...params} label="Rejestracja" size="small" />}
            />
            <TextField size="small" label="Licznik" InputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              endAdornment: <InputAdornment position="end">km</InputAdornment>
            }} onChange={kilometersChange} sx={{ flexGrow: 1 }} />
          </Box>

          <FormControl sx={{ width: '100%', pb: 2 }}>
            <InputLabel id="demo-multiple-chip-label">Czynności</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              value={activitiesSet}
              multiple
              disabled={!plate || !plate.type}
              onChange={activitiesSetChange}
              input={<OutlinedInput id="select-multiple-chip" label="Czynności" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((v) => {
                    const act = state.activities.find(x => x.id === v)
                    return (
                      <Chip sx={{
                        fontWeight: 'bold',
                        fontSize: 16,
                        color: 'white',
                        bgcolor: act.color
                      }} key={v} label={act.title} />
                    )
                  })}
                </Box>
              )}
            >
              {plate && plate.type ? state.activities.filter(x =>
                (plate.kind === 'truck' && x.vehicleType === null) ||
                (plate.kind === 'trailer' && x.vehicleType === plate.type)
              ).map(activity => (
                <MenuItem
                  key={activity.id}
                  value={activity.id}
                  style={activitiesStyles(activity.id, activitiesSet, theme)}
                >
                  {activity.title}
                </MenuItem>
              )) : ''}
            </Select>
          </FormControl>

          <TextField
            id="outlined-multiline-static"
            sx={{ width: '100%', pb: 3 }}
            size="small"
            label="Komentarz"
            value={comment}
            onChange={v => setComment(v.target.value)}
            multiline
            rows={6}
          />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <Box sx={{ width: 160 }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Data serwisu"
                  mask="__.__.____"
                  sx={{ height: 40 }}
                  value={date}
                  onChange={(newValue) => setDate(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <LoadingButton
              variant="contained"
              disableElevation
              endIcon={<AddIcon />}
              loading={loading}
              loadingPosition="end"
              disabled={!plate || kilometers === '' || !activitiesSet || activitiesSet.length === 0}
              onClick={handleSubmit}
            >Dodaj</LoadingButton>
          </Box>
        </Box>
        {/*<Box sx={{ pl: 2, height: 40, flexGrow: 1, lineHeight: '40px' }}>
          <Divider>100000 km</Divider>
        </Box>*/}
      </Box>
    </Box>
  )
}

export default AddService
